.accountPage {
    padding: 1.75rem 3rem;
    width:"50%";    

    .accountHeader {

        div {
            display: flex;
            align-items: center;
        }
    }
    .letter_spacing {
        letter-spacing: 3px;
    }
    .header {
        font-size: 1.25rem;
        color: #3abd98;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid #dfdfdf;
        margin-top: 3rem;
    }
    .editDiv {
        position: absolute;
        right: 0;
        top: 0;

        .edit {
            color: #3abd98;
            width: 7.5rem;
        }
    }
    .accountDetails {
        .errorMessage {
            color: red;
            margin: 20px;
            display: flex;
            justify-content: center;
        }
        .success {
            color: green;
        }
        input {
            padding: 1rem;
        }
        .accountPage-form{
            width:50%;
        }
    }
    form {
        display:flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 10rem;
            height: 10rem;
            border-radius: 100%;
            margin-bottom: 5rem;
        }
        .cameraIconDiv {
            background-color: #3abd98;
            border-radius: 100%;
            right: 28.5%;
            top: 48.5%;
        }
      
    }
    // &-form {
    //     width: 60%;
    // }
    input[type='checkbox'] + label {
        margin: 0.5rem 0;
    }
    input[type='checkbox'] {
        display: none;
    }
    input[type='checkbox'] + label:before {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
    input[type='checkbox']:checked + label:before {
        font-size: 1.5rem;
    }

    @media (max-width: 768px){
        form{
            width: 150%!important;
        }
    }
}

@media (max-width: 1000px) {
    .accountPage {
        padding: 0.75rem 1.5rem;
        

        // &-form {
        //     width: 100%;
        // }
    }
}

.hover{
    cursor:pointer
}