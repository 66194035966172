.StatTile {
    padding: 0;
    margin: 2.5rem 0;
    border-bottom: 1px solid #f2f2f2;
    .active {
        border-bottom: 3px solid black !important;
    }
    .userCount {
        padding: 0.5rem 2rem;

        h2 {
            font-weight: 600;
            margin: 0;
        }
        p {
            margin: 0;
            font-weight: 400;
        }
    }
}

@media (max-width: 700px) {
    .StatTile {
        display: grid !important;
        grid-template-columns: 1fr 1fr;

        .userCount {
            width: 100%;
        }
    }
}
