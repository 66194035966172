* {
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

.container {
  width: 100%;
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  .App {
      width: 90rem;
      margin: 0 auto;
  }
}



.colored-text{
  background: linear-gradient(to bottom right , #3abd98,#3abd98)!important;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent!important;
}
.datePickerStyle{
  height: 33px;
}
