.QM {

    @import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);

    $border-radius-size: 14px;

    $question: #3abd98;

    *, *:before, *:after {
    box-sizing: border-box;
    }



    .slide-container {
    margin: auto;
    width: 600px;
    text-align: center;
    }

    .wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    
    &:focus {
        outline: 0;
    }
    }



    .q-card {
    background: white;
    width: 300px;
    display: inline-block;
    margin: auto;
    border-radius: $border-radius-size + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px black;
    z-index: -1;
    }

    .chips{
        margin-bottom: 6px;
        margin-left: 2px;
        margin-right: 2px;
        font-size: 10px!important;
    }
    .mainChip{
        border-color:#3abd98!important;
        background-color: rgba(77,194,161,.15);
        margin-left:2px;
        font-size: 11px!important;
        text-transform: uppercase;

    }
    .q-card__level {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
    text-align: left!important;
    margin:10px 8px 10px 8px;
    }

    .q-card__unit-name {
    font-size: 15px!important;
    color: black;
    font-weight: 600;
    margin-bottom: 5px;
    padding: 20px;
    padding-bottom:0px;
    text-align: left;
    }

    .other_tags{
        text-align: left!important;
        margin-top: 15px;
        padding-left: 15px;
    }

    .q-card__unit-description {
    padding: 10px;
    margin-bottom: 10px;  
        .data{
            margin-top: 6px;
            margin-left: 10px;
            font-size: 18px;
        }
    }

    .q-card__unit-stats--question {
    background: $question;
    
    .one-third {
        border-right: 1px solid #fff;
    }
    }
    .menuIcon:hover{
        background-color: transparent!important;
    }

    .q-card__unit-stats {
    
    color: white;
    font-weight: 400;
    border-bottom-left-radius: $border-radius-size;
    border-bottom-right-radius: $border-radius-size;
    
    .one-third {
        width: 25%;
        float: left;
        padding: 5px 2px 5px 2px;
    }
    
    sup {
        position: absolute;
        bottom: 4px; 
        font-size: 45%;
        margin-left: 2px;
    }
    
    .stat {
        position: relative;
        font-size: 16px;
        margin-bottom: 10px;
    }
    
    .stat-value {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;
    }

    .no-border {
        border-right: none;
    }
    }

    .clearfix:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }

    .slick-prev {
    left: 100px;
    }

    .slick-next {
    right: 100px;
    }
    padding: 1.75rem 3rem;

    .dash {
        border-top: 2px solid;
        width: 25px;
    }
    .DropdownElem {
        right: 10px;
        top: 12px;
    }
    .truncate {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .statusBtns{
        button{
            width:200px;
            margin-bottom: 20px;
            margin-right: 20px;
        }
    }
    .downloadBtnDropdown{
        button{
            padding:8px;
            width:200px;
            margin-right: 20px;
        }
    }
    .btns{
        margin-right: 2rem;

    }
    .tag-wrapper{
        display: flex;
        flex-wrap: wrap;
        .tags{
            margin: 2px 4px 2px 4px;
            background:blue;
            color: #fff;
        }
    }
    .categorySelect{
        width: 250px;
        margin-left:30px;
        margin-top: -14px;
    }
    .select-status{
        width: 150px;
        margin-left:30px;
        margin-top: -14px;
    }
    
}

@media (max-width: 1000px) {
    .Issue {
        padding: 0.75rem 1.5rem;

        .mid {
            flex-direction: column;

            .btn {
                padding: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }
}

@media (max-width: 500px) {
    .Issue {
        .btns {
            width: 100% !important;
            flex-direction: column!important;
            margin-right: 0rem!important;
        }
        .btnsAndPagination{
            flex-direction: column;
        }
        .statusBtns{
            flex-direction: column;
            width: 100% !important;
        }
    }
}

.popupForm{
    .promo-popup-btns{
      button{
        padding:7px;
      }
    }
    @media(max-width:768px){
      .promo-popup-btns{
        flex-direction: column!important;
        button{
          margin-bottom: 10px!important;
          width: 100%;
        }
      }
  }
}

.popupAdd{
    .addChoiceIcon{
        font-size: 21px;
        margin-left: 20px;
        color:#fff;
        cursor: pointer;

    }
    .addChoiceButton{
        margin-right: 38px;
        margin-bottom: 8px;
        background-color: limegreen;
        color: #fff;
        &:hover{
            background-color: rgb(64, 219, 25);
        }
    }

    .pollLabel{
        display: flex;
        justify-content: space-between;
        .pollText{
            margin-top: 15px;
            float: left;
        }
    }

    .removeChoiceIcon{
        font-size: 28px;
        margin-left: 10px;
        margin-top: 6px;
        color:rgb(136, 21, 21);
        cursor: pointer;
        &:hover{
            color:rgb(179, 59, 59);
        }
    }
}
  
