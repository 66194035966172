.body-paper{
    padding:25px 25px;
    margin: auto;
    width: 800px;

    .stats{
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        vertical-align: middle;
        


        .stat-icon{
            margin-right:15px;
            height: 30px;
            align-items: center;
        }
    }

    .tags{
        width:50%;
        .chips{
            margin:10px 3px 2px 3px ;
            background: lightgreen;
            &:nth-child(1){
                background: aqua!important;
            }
        }
        
    }

}