.customizeDropdown {
    position: relative;
    display: inline-block;

    &:hover .headerDropdownContent {
        display: block;
    }
}
.headerDropdownContent {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    min-width: 20rem;
    box-shadow: 0px 0px 4px 2px #0000000d;
    z-index: 1;

    &-div {
        height: 20rem;
    }

    button {
        color: black;
        padding: 10px 16px;
        text-decoration: none;
        display: block;
        border: none;
        background-color: white;
    }
}
.accountLink {
    .profileImg {
        width: 3.5rem;
        height: 3.5rem;
    }
}

.nav {
    width: 100%;
    background-color: white;
    position: relative;
}

.nav > .nav-header {
    display: inline;
}

.nav > .nav-header > .nav-title {
    display: inline-block;
}

.nav > .nav-btn {
    display: none;
}

.nav > .nav-links {
    display: flex;
    align-items: center;
}

.nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: black;
}

.nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.nav > #nav-check {
    display: none;
}

.searchDiv {
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    margin: 0 1.5rem;

    input {
        padding: 1rem;
        border: none;

        &:focus {
            outline: none;
        }
    }
}

@media (max-width: 768px) {
    .nav > .nav-btn {
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .nav > .nav-btn > label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 10px;
    }
    .nav > .nav-btn > label:hover,
    .nav #nav-check:checked ~ .nav-btn > label {
        background-color: white;
    }
    .nav > .nav-btn > label > span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #ff5b5f;
    }
    .nav > .nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: white;
        height: 10px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px;
        border-radius: 4px;
        z-index: 100;
    }
    .nav > .nav-links > a {
        display: block;
        width: 100%;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
        height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
        height: 15rem;
        overflow-y: auto;
        overflow: visible;
    }
    .customizeDropdown {
        margin: 1rem;
        .headerDropdownContent {
            position: absolute;
            left: 5.5rem;
            top: 0;
            z-index: 1000;
        }
    }
    .accountLink {
        margin-left: 1rem;
    }
    .searchDiv {
        width: 80%;
        margin: 0.5rem 1rem;
    }
}
