.userListPage {
    padding: 1.75rem 3rem;

    .dropdownButtons {
        // display: grid;
        // grid-template-columns: 3fr 1fr;
        display: flex;
        align-items: center;

        .dropdownDiv {
            .dropdown {
                margin-left: 0;
            }
        }
        .btns {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            button {
                margin-right: 1.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }
    .userCountDiv {
        padding: 0;
        margin: 2.5rem 0;
        border-bottom: 1px solid #f2f2f2;

        .userCount {
            padding: 0.5rem 2rem;

            h2 {
                font-weight: 600;
                margin: 0;
            }
            p {
                margin: 0;
                font-weight: 400;
            }
        }
        .active {
            border-bottom: 4px solid #000;
        }
    }
    .topBar {
        font-weight: 600;
        background-color: #fdfdfd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #dfdfdf;
    }
    .btn {
        width: 200px;
        border-radius: 5px!important;
    }
}
.downloadBtnDropdown {
    position: relative;
    display: inline-block;

    &:hover .dropdownContent {
        display: block;
    }
    .dropdownContent {
        display: none;
        position: absolute;
        left: 0;
        width: 10.6rem;
        background-color: white;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        button {
            color: black;
            padding: 10px 14px;
            text-decoration: none;
            display: block;
            border: none;
            background-color: white;
            font-size: 13px;

            &:hover {
                cursor: pointer;
                background-color: #ddd;
            }
        }
    }
}

@media (max-width: 1000px) {
    .userListPage {
        padding: 0.75rem 1.5rem;

        .mid {
            flex-direction: column;

            .btn {
                padding: 0.5rem;
            }
        }
    }
}

@media (max-width: 500px) {
    .userListPage {
        .btns {
            display: grid;
            grid-template-columns: 1fr 1fr !important;
        }
    }
}
