.Issue {
    padding: 1.75rem 3rem;

    .dash {
        border-top: 2px solid;
        width: 25px;
    }
    .DropdownElem {
        right: 10px;
        top: 12px;
    }
    .truncate {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .statusBtns{
        button{
            width:200px;
            margin-bottom: 20px;
            margin-right: 20px;
        }
    }
    .downloadBtnDropdown{
        button{
            padding:8px;
            width:200px;
            margin-right: 20px;
        }
    }
    .btns{
        margin-right: 2rem;

    }
}

@media (max-width: 1000px) {
    .Issue {
        padding: 0.75rem 1.5rem;

        .mid {
            flex-direction: column;

            .btn {
                padding: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }
}

@media (max-width: 500px) {
    .Issue {
        .btns {
            width: 100% !important;
            flex-direction: column!important;
            margin-right: 0rem!important;
        }
        .btnsAndPagination{
            flex-direction: column;
        }
        .statusBtns{
            flex-direction: column;
            width: 100% !important;
        }
    }
}
