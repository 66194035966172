.customizeDropdown {
    position: relative;
    display: inline-block;
  
    &:hover .dropdownContent {
      display: block;
    }
    .MuiSvgIcon-root {
      color: black;
    }
  }
  
  .dropdownContent {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    min-width: 10rem;
    box-shadow: 0px 0px 4px 2px #0000000d;
    z-index: 1;
  
    button {
      color: black;
      padding: 10px 16px;
      text-decoration: none;
      display: block;
      border: none;
      background-color: white;
  
      &:hover {
        cursor: pointer;
        background-color: #ddd;
      }
    }
  }