.confirmPopup {
    width: 500px;
}

@media (max-width: 1000px) {
    .confirmPopup {
        width: auto;

        .btnLeft {
            margin-right: 0.5rem;
        }
    }
}
