//  flex classes
.flex {
    display: flex !important;
}

//direction
.flex-row {
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-col {
    flex-direction: column !important;
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

.flex-1 {
    flex: 1 1 0%;
}

//wrap
.flex-wrap {
    flex-wrap: wrap;
}

//align-items
.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}
.items-baseline {
    align-items: baseline;
}

// align-self

.self-auto {
    align-self: auto;
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.self-stretch {
    align-self: stretch;
}

// justify-content
.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}
.justify-evenly {
    justify-content: space-evenly;
}
.w-full {
    width: 100%;
}
.w-half {
    width: 50%;
}
.w-0 {
    width: 0px;
}

// widths ------------------

.w-1\/12 {
    width: 8.333333%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-33 {
    width: 33.333333%;
}

.w-50 {
    width: 50%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 65%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-100 {
    width: 100%;
}

.w-15 {
    width: 15%;
}

.h-full {
    height: 100%;
}

.h-100vh {
    height: 100vh;
}
.fit-content {
    width: fit-content !important;
}
// --------- font-weights ----------
$weights: 100, 200, 300, 400, 500, 600, 700;

@each $weight in $weights {
    .weight-#{$weight} {
        font-weight: $weight;
    }
}

.Manrope {
    font-family: Manrope;
}
// --------------------
// ---- text--center ------
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

@for $i from 1 through 42 {
    .text-#{$i} {
        font-size: $i + 'px';
    }
}

.jumbotron {
    font-size: 60px;
}

// text colors
.text-white {
    color: #fff;
}
.text-black {
    color: #000;
}

.mr-5 {
    margin-right: 5px;
}
.no-shadow {
    box-shadow: none !important;
}

// text transform
.uppercase {
    text-transform: uppercase;
}
.lowercase {
    text-transform: lowercase;
}
.capitalize {
    text-transform: capitalize;
}

//disbaled
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
.pointer-all {
    pointer-events: all;
}

// position
.relative {
    position: relative;
}
.relative-imp {
    position: relative !important;
}
.absolute {
    position: absolute;
}
.line-normal {
    line-height: normal;
}
.line-45 {
    line-height: 1.45;
}
.line-2 {
    line-height: 2;
}
.line-56 {
    line-height: 1.56;
}
.line-22 {
    line-height: 1.22;
}
.outline-none {
    outline: none;
}
.text-end {
    text-align: end;
}
.line-21 {
    line-height: 21px;
}
.no-scroll {
    overflow: hidden !important;
}

.hidden {
    visibility: hidden;
}

.fixed {
    position: fixed;
}
.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.bottom-10 {
    bottom: 10px;
}
.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}
.opacity-0 {
    opacity: 0;
}
.opacity-5 {
    opacity: 0.5;
}
.opacity-1 {
    opacity: 1;
}
.opacity-8 {
    opacity: 0.8;
}
.cursor-pointer {
    cursor: pointer;
}
button {
    cursor: pointer;
}

a:-webkit-any-link {
    color: unset;
    text-decoration: none;
}
.rotate-180 {
    transform: rotate(180deg);
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}
.underline {
    text-decoration: underline;
}
.mb-0 {
    margin-bottom: 0px;
}

// generates text-12 class for font-sizes
@for $i from 10 through 40 {
    .text-#{$i} {
        font-size: 1px * $i !important;
    }
}

//padding
@for $i from 0 through 30 {
    .p-#{$i*2} {
        padding: 2px * $i !important;
    }
}

//padding-bottom
@for $i from 0 through 30 {
    .pb-#{$i*2} {
        padding-bottom: 2px * $i !important;
    }
}

//padding-top
@for $i from 0 through 30 {
    .pt-#{$i*2} {
        padding-top: 2px * $i !important;
    }
}

//padding-left
@for $i from 0 through 30 {
    .pl-#{$i*2} {
        padding-left: 2px * $i !important;
    }
}

//padding-right
@for $i from 0 through 30 {
    .pr-#{$i*2} {
        padding-right: 2px * $i !important;
    }
}

//padding-vertical
@for $i from 0 through 30 {
    .py-#{$i*2} {
        padding-top: 2px * $i !important;
        padding-bottom: 2px * $i !important;
    }
}
//padding-horizontal
@for $i from 0 through 30 {
    .px-#{$i*2} {
        padding-right: 2px * $i !important;
        padding-left: 2px * $i !important;
    }
}

//margin-vertical
@for $i from 0 through 30 {
    .my-#{$i*2} {
        margin-top: 2px * $i !important;
        margin-bottom: 2px * $i !important;
    }
}
//margin-horizontal
@for $i from 0 through 30 {
    .mx-#{$i*2} {
        margin-right: 2px * $i !important;
        margin-left: 2px * $i !important;
    }
}

// margin-top
@for $i from 0 through 100 {
    .mt-#{$i*2} {
        margin-top: 2px * $i !important;
    }
}

// margin-bottom
@for $i from 0 through 100 {
    .mb-#{$i*2} {
        margin-bottom: 2px * $i !important;
    }
}

//margin-left
@for $i from 0 through 200 {
    .ml-#{$i*2} {
        margin-left: 2px * $i !important;
    }
}

// margin-right
@for $i from 0 through 100 {
    .mr-#{$i*2} {
        margin-right: 2px * $i !important;
    }
}
// margin-top & margin-bottom
@for $i from 0 through 100 {
    .my-#{$i*2} {
        margin-top: 2px * $i !important;
        margin-bottom: 2px * $i !important;
    }
}
// margin-left & margin-right
@for $i from 0 through 100 {
    .mx-#{$i*2} {
        margin-right: 2px * $i !important;
        margin-left: 2px * $i !important;
    }
}

// border
@for $i from 0 through 20 {
    .b-#{$i*2} {
        border-radius: 2px * $i !important;
    }
}

// border-top-left
@for $i from 0 through 20 {
    .btl-#{$i*2} {
        border-top-left-radius: 2px * $i !important;
    }
}

// border-top-right
@for $i from 0 through 20 {
    .btr-#{$i*2} {
        border-top-right-radius: 2px * $i !important;
    }
}

// border-bottom-left
@for $i from 0 through 20 {
    .btr-#{$i*2} {
        border-bottom-left-radius: 2px * $i !important;
    }
}

// border-bottom-right
@for $i from 0 through 20 {
    .btr-#{$i*2} {
        border-bottom-right-radius: 2px * $i !important;
    }
}

// height
@for $i from 0 through 30 {
    .height-#{$i*2} {
        height: 2px * $i !important;
    }
}

// width
@for $i from 0 through 30 {
    .width-#{$i*2} {
        width: 2px * $i !important;
    }
}

// margin-right
@for $i from 0 through 100 {
    .cg-#{$i*2} {
        column-gap: 2px * $i !important;
    }
}

::-moz-selection {
    /* Code for Firefox */
    background-color: #f8c2b9;
}

::selection {
    background-color: #f8c2b9;
}
.no-margin {
    margin: 15px 0 !important;
    // overwriting  this for HotelsCreateForm.js line no. 172
}

.hide {
    display: none;
}

.grid {
    display: grid;
}

@for $i from 4 through 8 {
    .radius-#{$i} {
        border-radius: $i + 'px' !important;
    }
}

@for $i from 1 through 12 {
    .grid-gap-#{$i} {
        grid-gap: 1px * $i !important;
    }
}
// grid-cols
@for $i from 1 through 12 {
    .grid-cols-#{$i} {
        grid-template-columns: repeat($i, minmax(0, 1fr)) !important;
    }
}

// grid-rows
@for $i from 1 through 12 {
    .grid-rows-#{$i} {
        grid-template-rows: repeat($i, minmax(0, 1fr)) !important;
    }
}

.deleteButton {
    background-color: white;
    border: 1px solid #ff5b5b;
    color: #ff5b5b;
    padding: 9px 45px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.greenButton {
    background-color: #42da93;
    border: 1px solid #42da93;
    color: white;
    padding: 9px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.redButton {
    background-color: #ff5b5b;
    border: 1px solid #ff5b5b;
    color: #ffffff;
    padding: 9px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.greyButton {
    background-color: white;
    border: 1px solid #979797;
    color: #979797;
    padding: 9px 45px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.greenTag {
    background-color: white;
    border: 2px solid #42da93;
    color: #42da93;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.normalButton {
    background-color: white;
    //padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 100px;
    max-width: 6rem;
}

.checkbox {
    input[type='checkbox'] + label {
        display: block;
        cursor: pointer;
    }
    input[type='checkbox'] {
        display: none !important;
    }
    input[type='checkbox'] + label:before {
        content: '✓';
        border: 1px solid #000;
        border-radius: 5px;
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: bottom;
        color: transparent;
    }
    input[type='checkbox']:checked + label:before {
        text-align: center;
        font-size: 1rem;
        background-color: white;
        border-color: #3abd98;
        color: #3abd98;
    }
}

.userLogo {
    right: 30px;
    top: 20px;
}

.ant-picker {
    width: 174px !important;
    border: 1px solid black !important;
    color: blue !important;
}

.ant-picker-suffix {
    color: black !important;
}

.ant-picker-header {
    background-color: #1976d2 !important;
    height: 50px !important;
    width: 250px !important;
    color: white !important;
    font-size: 23px !important;
    padding-top: 5px !important;
}

.ant-picker-header-view button:hover {
    color: white !important;
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
    transform: rotate(-45deg);
    color: white !important;
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
    color: white !important;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
    display: flex;
    flex-direction: column;
    width: 250px !important;
}

.ant-picker-date-panel .ant-picker-body {
    padding: 8px 0px !important;
}

.ant-picker-panel .ant-picker-footer {
    display: none !important;
}

.customizeDropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdownContent {
    display: block;
  }
  .MuiSvgIcon-root {
    color: black;
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 10rem;
  box-shadow: 0px 0px 4px 2px #0000000d;
  z-index: 1;

  button {
    color: black;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    border: none;
    background-color: white;

    &:hover {
      cursor: pointer;
      background-color: #ddd;
    }
  }
}

.customizeDropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdownContent {
    display: block;
  }
  .MuiSvgIcon-root {
    color: black;
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 10rem;
  box-shadow: 0px 0px 4px 2px #0000000d;
  z-index: 1;

  button {
    color: black;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    border: none;
    background-color: white;

    &:hover {
      cursor: pointer;
      background-color: #ddd;
    }
  }
}

