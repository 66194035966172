.btn {
    display: block;
    background-color: rgb(58, 189, 152);
    color: #fff;
    border: none;
    padding: 0.85rem;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
}
.loginBtn {
    font-size: 16px;
    font-weight: 600;
}
.accountBtn {
    display: block;
    padding: 0.75rem 2.5rem;
    margin: 3rem auto;
    font-weight: 400;
    border-radius: 10px;
    width: auto;
}
.changePasswordPageBtn {
    font-size: 24px;
    margin-top: 3.5rem;
}
.popupBtn {
    display: inline;
    border: 1px solid #3abd98;
    color: #3abd98;
    background-color: white;
    padding: 0.35rem 2.5rem;
}
.profilePageBtn {
    display: inline;
    border: 1px solid #3abd98;
    color: #3abd98;
    background-color: white;
    padding: 0.5rem 3.5rem;
    border-radius: 10px;
    width: auto;
}
.adminsPageBtn {
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
        margin-right: 0.5rem;
    }
}
.btnBlackOutline {
    border: 1px solid black;
    color: black;
}
.orangeFill {
    background-color: #ff5b5b;
    color: white;
}
.displayNone {
    display: none;
}

@media (max-width: 1000px) {
    .btn,
    .popupBtn,
    .profilePageBtn {
        padding: 0.5rem 1.5rem;
    }
}
