.downloadPopup {
    .progressBar {
        background-color: #ddd;
        border-radius: 13px;
        padding: 1px;

        div {
            background-color: #ff5b5b;
            /* Adjust with JavaScript */
            height: 5px;
            border-radius: 10px;
        }
    }
    .btn {
        width: auto;
    }
}
