.CM{
    padding: 1.75rem 3rem;

    .divider{
        height: 70%;
    }

    .select-status{
        width: 150px;
        margin-left:30px;
        margin-top: -14px;
    }

    .topbar{
        display: flex;
        button::after{
            content:"Create New Category"
        }
        @media (max-width:768px){
            flex-direction: column;
            width: calc(95vw - 25%);
            button{
                margin-bottom: 20px;
            }
            .select-status{
                width: calc(95vw - 40%)!important;
            }
        }
    }
    .card-category{
        padding:25px;
        margin-top: 16px;
        height: 100%;
    }

    @media (max-width:750px){
        padding:10px;
        .select-status{
            margin-top:0px!important;
        }
        .topbar{
            display: flex;
            button{
                padding: 5px!important;
            }
            button::after{
                content:"Create Category"
            }
        }
        .card-category{
            width: calc(95vw - 8%);

        }
        
    }

    @media (max-width:426px){
        .card-category{
            width: calc(95vw - 8%);

        }
    }
}