.topBar {
    font-weight: 600;
    background-color: #fdfdfd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #dfdfdf;
    padding: 10px 30px !important;
}
.profileImg {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
}
.popupCard {
    transition: all 0.4s 0.2s;
    background-color: white;

    .row {
        padding: 0.5rem;

        &:hover {
            box-shadow: 0px 4px 4px 0px #0000000d;
            background-color: #fdfdfd;
            border-radius: 4px;
        }
    }

    input[type='checkbox'] + label {
        display: block;
        cursor: pointer;
    }
    input[type='checkbox'] {
        display: none;
    }
    input[type='checkbox'] + label:before {
        content: '✓';
        border: 2px solid #000;
        border-radius: 4px;
        display: inline-block;
        width: 35px;
        height: 35px;
        vertical-align: bottom;
        color: transparent;
    }
    input[type='checkbox']:checked + label:before {
        text-align: center;
        font-size: 14.5px;
        background-color: white;
        border: 2px solid #ff5b5b;
        color: #ff5b5b;
    }

    .btn {
        width: auto;
        margin: 0 0.5rem;
    }
}

.progressBar {
    background-color: #ddd;
    border-radius: 13px;
    padding: 1px;

    div {
        background-color: #ff5b5b;
        width: 67%;
        /* Adjust with JavaScript */
        height: 5px;
        border-radius: 10px;
    }
}

@media (max-width: 1000px) {
    .customTable {
        overflow-x: auto;

        &-div {
            width: 70rem;
        }
    }
}
