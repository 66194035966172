.analyticsPage {
    transition: all 0.3s ease;
    padding: 1.75rem 3rem;

    .dash {
        border-bottom: 2px solid;
        width: 25px;
    }

    .graph {
        transition: all 0.3s ease;
        width: 60%;

        .arrows {
            position: absolute;
            right: 1rem;
            top: 0;
            color: grey;

            .arrow {
                border: 1px solid grey;
                border-radius: 20px;
            }
        }
        .zoom {
            position: absolute;
            right: 1rem;
            top: 4rem;
            color: grey;
            cursor: pointer;
        }
    }
    .countTrending {
        flex-direction: column;
        width: 35%;

        .infoGroup {
            margin-bottom: 10px !important;
        }
    }

    .trending {
        span {
            border: 2px solid rgb(212, 69, 186);
            padding: 0.3rem 1rem;
            margin: 0.55rem 0.75rem 0 0;
            display: inline-block;
        }
    }
}
.map {
    position: relative;

    .tools {
        color: grey;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        z-index: 1000;

        .zoomIcon {
            border: 1px solid grey;
            border-radius: 100%;
            padding: 3px 5px;
            cursor: pointer;
        }
    }
}

@media (max-width: 1000px) {
    .analyticsPage {
        padding: 0.75rem 1.5rem;

        .middleLayer {
            flex-direction: column;

            .graph {
                width: 100%;
            }
            .countTrending {
                flex-direction: row;
                width: 100%;
                margin-top: 2rem;

                .trending {
                    margin-left: 1rem;
                }
            }
        }
        .lowerLayer {
            flex-direction: column;

            .graph {
                margin-top: 2rem;
                width: 100% !important;
            }
        }
    }
}
@media (max-width: 500px) {
    .analyticsPage {
        .pickers {
            flex-direction: column;

            &-slider {
                margin: 0 auto !important;
                width: 50% !important;
            }
            .ant-picker {
                width: auto !important;
            }
        }
        .middleLayer {
            .countTrending {
                flex-direction: column;

                .trending {
                    margin-left: 0;
                }
                .infoGroup1 {
                    flex-direction: column;
                }
                .infoGroup {
                    padding: 0.5rem 0.75rem;
                    width: 100%;
                }
            }
        }
    }
}

.graphPage{
    .middleLayer{
        height: 80%;
    }
}
