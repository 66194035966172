.SQ {
    padding: 1.75rem 3rem;

    .dash {
        border-top: 2px solid;
        width: 25px;
    }
    .DropdownElem {
        right: 10px;
        top: 12px;
    }
    .truncate {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn {
        width: auto;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }
}

@media (max-width: 1000px) {
    .SQ {
        padding: 0.75rem 1.5rem;

        .mid {
            flex-direction: column;

            .btn {
                padding: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
        .btns {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media (max-width: 500px) {
    .SQ {
        .btns {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}
