.formInput {
    label {
        display: block;
        color: #696f79;
        margin: 2rem 0 0.25rem 0;
    }
    input {
        padding: 1rem 1.25rem;
        border: 1px solid #8692a6;
    }
    p {
        color: red !important;
        margin: 0;
    }
    .eye {
        top: 1.75rem;
        right: 0.25rem;

        .MuiButtonBase-root {
            padding: 0.5rem 0.85rem;
        }
    }
}
.accountPageInput {
    label {
        color: #312e2e;
        font-weight: 600;
        margin: 1.25rem 0 0.5rem 0;
    }
}
