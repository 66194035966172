.loginPage {

    .logo{
        align-items: center;
        .logo-img{
            height: 40px;
            width: 42px;
    
            img{
                width: inherit;
                border-radius: 50%;
            }
        }
    
        .logo-txt{
            text-shadow: 0px 1px, 1px 0px, 1px 1px;
            font-size: 24px;
        }
    }
    
    .signup-btn{
        button{
            background:#3abd98;
            color: #fff!important;
        }

        :hover{
            background:rgb(72, 140, 161) ;
        }
    }
    .formDiv {
        margin: 5rem auto;
        width: 27.5%;

        .loginText {
            h1 {
                background: #3abd98;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0;
            }
            p {
                background: #3abd98;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0;
                margin: 0.5rem 0 2.5rem 0;
            }
        }
        .forgotPasswordInput {
            input {
                padding: 1rem 1.25rem;
                border: 1px solid #8692a6;
            }
            p {
                color: #8692a6;
            }
            span {
                color: #ff5b5b;
                cursor: pointer;
            }
        }
        .forgotPasswordInputWithValidator {
            input {
                padding: 1rem 1.25rem;
                border: 1px solid #8692a6;
            }
            label {
                margin-top: 1.5rem;
            }
            p {
                color: #8692a6;
            }
            span {
                margin-left: 0.5rem;
            }
            .spanSign {
                display: inline;
                color: #ff5b5b;
                cursor: pointer;
                margin-left: 0.1rem;
            }
        }
    }
    .forgot {
        color: #ff5b5b;
        display: flex;
        justify-content: center;
        cursor:pointer;
        margin: 1rem 0 1rem 0;
    }
    .letterSpacing {
        letter-spacing: 2px;
    }
    .popup {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(27, 25, 25, 0.9);
        z-index: 1;
        transition: all 0.3s;

        .popupCard {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 30%;
            height: auto;
            transition: all 0.4s 0.2s;
            background-color: white;
            padding: 2rem 3rem;

            p {
                color: #8692a6;
            }
            button {
                display: block;
                background-color: #ff5b5b;
                color: #fff;
                border: none;
                padding: 0.75rem;
                border-radius: 6px;
                font-weight: 500;
                font-size: 16px;
                margin-top: 2rem;
            }
        }
    }
}

@media (max-width: 1000px) {
    .loginPage {
        .formDiv {
            width: 50%;
        }
    }
}

@media (max-width: 500px) {
    .loginPage {
        .formDiv {
            width: 75%;
        }
    }
}
