.adminsPage {
    padding: 1.75rem 3rem;

    .adminCountDiv {
        padding: 0;
        margin: 2.5rem 0;
        border-bottom: 1px solid #f2f2f2;

        .userCount {
            padding: 0.5rem 2rem;

            h2 {
                font-weight: 600;
                margin: 0;
            }
            p {
                margin: 0;
                font-weight: 400;
            }
        }
        .active {
            border-bottom: 4px solid #000;
        }
    }
    .btns {
        align-items: center;

        button {
            margin-right: 1.5rem;
        }
    }
    .btn {
        width: auto;
    }
}
.adminDetailsPage {
    padding: 1.75rem 3rem;

    .adminInfo {
        border-bottom: 1px solid #dfdfdf;

        .tag {
            display: inline-block;
            border: 2px solid #42da93;
            padding: 0.2rem 1.75rem;
            border-radius: 100px;
            color: #42da93;
            margin-bottom: 1.5rem;
        }
        .inactive {
            border: 2px solid #ffdf37;
            color: #ffdf37;
        }
        .deleted {
            color: red;
            border: 2px solid red;
        }
        .blocked {
            color: grey;
            border: 2px solid grey;
        }
        button {
            margin-left: 2rem;
        }
    }
}
.popupForm {
    .form {
        label {
            padding-bottom: 0.25rem;
        }
        input {
            margin-bottom: 1rem;
            border-radius: 6px;
            font-size: 14px;
            padding: 1rem 1.25rem;
            border: 1px solid #8692a6;
        }
    }
}
.iconSwitches {
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 30px;
        margin-top: 0.5rem;
    }
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .slider:before {
        position: absolute;
        content: '';
        height: 23px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input:checked + .slider {
        background-color: #3abd98;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #3abd98;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(29px);
        -ms-transform: translateX(29px);
        transform: translateX(29px);
    }
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
}
.switches {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    grid-template-rows: min-content;
    place-items: end;
}
.MuiDialog-container {
    ::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 1000px) {
    .adminDetailsPage {
        padding: 0.75rem 1.5rem;

        .adminInfo {
            flex-direction: column;

            .btn {
                margin-top: 0.75rem;
            }
        }
    }
    .adminsPage {
        padding: 0.75rem 1.5rem;
    }
}

@media (max-width: 1000px) {
    .adminDetailsPage {
        .customTable {
            overflow-x: auto;

            &-div {
                width: 30rem;
            }
        }
    }

    .adminsPage {
        .mid {
            flex-direction: column;
        }
        .btns {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr;

            .btn {
                padding: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }

    .popupForm {
        padding: 0.75rem 1.5rem !important;

        .customTable {
            overflow-x: auto;

            &-div {
                width: 30rem;
            }
        }
    }
}

@media (max-width: 500px) {
    .adminsPage {
        .btns {
            grid-template-columns: 1fr 1fr;
        }
    }
}
