.individualReviews{
    #testimonials{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width:100%;
    }
  
    .testimonial-box-container{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width:100%;
        overflow-wrap: break-word;
    }
    .testimonial-box{
        width:100%;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        background-color: #ffffff;
        padding: 20px;
        margin: 15px;
        margin-left: 0px;
        cursor: pointer;
    }
    .profile-img{
        width:50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
    }
    .profile-img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .profile{
        display: flex;
        align-items: center;
    }
    .name-user{
        display: flex;
        flex-direction: column;
    }
    .name-user strong{
        color: #3d3d3d;
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }
    .name-user span{
        color: #979797;
        font-size: 0.8rem;
    }
    .reviews{
        color: #f9d71c;
        margin-left: 28%;
    }
    .box-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .client-comment p{
        font-size: 0.9rem;
        color: #4b4b4b;
        width: 80%;
    }
    
    
    ::selection{
        color: #ffffff;
        background-color: #252525;
    }

    @media(max-width:768px){
        .box-top{
            flex-direction: column;
        }
        .testimonial-box{
            width: 100%!important;
        }
        .testimonial-box-container{
            padding: 0px;
        }
        .client-comment{
            p{
                width: 100%;
            }
        }
    }
}