.profilePage {
    padding: 1.75rem 3rem;

    .profileTop {
        border-bottom: 1px solid #dfdfdf;

        .infoGroups {
            width: 29rem;

            .btns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0.5rem;
            }
        }
        img {
            object-fit: cover;
            width: 10rem;
            height: 9rem;
            border-radius: 10px;
        }

        .userInfo {
            .tag {
                display: inline-block;
                border: 2px solid #42da93;
                padding: 0.2rem 1.75rem;
                border-radius: 100px;
                color: #42da93;
                margin-bottom: 1rem;
            }
            .inactive {
                border: 2px solid #ffdf37;
                color: #ffdf37;
            }
            .deleted {
                color: #ff5b5b;
                border: 2px solid #ff5b5b;
            }
            .blocked {
                color: grey;
                border: 2px solid grey;
            }
            span {
                font-weight: 700;
            }
        }
    }
    .orange {
        color: #ff5b5b;
    }
}
.friendsPage {
    padding: 1.75rem 3rem;

    .optionsDiv {
        button {
            width: auto;
            margin-right: 1rem;
        }
        .dropdowns {
            .dropdown {
                margin: 0;
                margin-left: 1rem;
            }
        }
    }
}
.checkbox {
    input[type='checkbox'] + label {
        display: block;
        cursor: pointer;
    }
    input[type='checkbox'] {
        display: none !important;
    }
    input[type='checkbox'] + label:before {
        content: '✓';
        border: 1px solid #000;
        border-radius: 4px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: bottom;
        color: transparent;
    }
    input[type='checkbox']:checked + label:before {
        text-align: center;
        font-size: 1rem;
        background-color: white;
        border-color: #3abd98;
        color: #3abd98;
    }
}
.dropdownContent {
    width: 11.75rem;

    button {
        width: 100% !important;
    }
}

@media (max-width: 1000px) {
    .profilePage {
        padding: 0.75rem 1.5rem;

        .profileTop {
            flex-direction: column;
            align-items: center;

            .infoGroups {
                margin-top: 2rem;
            }
        }
        .customTable {
            overflow-x: auto;

            &-div {
                width: 50rem;
            }
        }
    }
    .friendsPage {
        padding: 0.75rem 1.5rem;
    }
}

@media (max-width: 500px) {
    .profilePage {
        .profileTop {
            &-left {
                flex-direction: column;
            }
            .infoGroups {
                width: auto;

                &-in {
                    flex-direction: column;

                    .infoGroup {
                        margin-bottom: 1rem !important;
                    }
                }
                .btns {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
    .friendsPage {
        .optionsDiv {
            flex-direction: column;

            .btns {
                display: grid !important;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
