.table {
    //   border: 1px solid black;
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    //   grid-template-rows: repeat(10, 1fr);
    //   grid-row-gap: 15px;
    //   border-top: 1px solid black;
    //   border-right: 1px solid black;
}

.table > span {
    //   padding: 8px 4px;
    //   border-left: 1px solid black;
    //   border-bottom: 1px solid black;
}

.headers {
    background: #fcfcfc;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 5px 5px 0px 0px;
    column-gap: 1rem;
}

.dataRows {
    column-gap: 1rem;

    .truncate {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
