.infoGroup {
    background-color: #fcfcfc;
    border: 1px solid #dfdfdf;
    padding: 1rem 2rem;

    h3,
    p {
        margin: 0;
    }
}
