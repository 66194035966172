.sideBar {
    min-width: 225px;
    height: 100vh;
    background: rgba(223, 223, 223, 0.1);
    transition: all 0.5s;
    overflow-x: hidden;
    position: sticky;
    top: 0;


    .logo {
        align-items: center;

        .logo-img {
            height: 40px;
            width: 42px;

            img {
                width: inherit;
                border-radius: 50%;
            }
        }

        .logo-txt {
            margin-left: 8px;
            text-shadow: 0px 1px, 1px 0px, 1px 1px;
            font-size: 24px;
        }
    }

    .disabled {
        color: grey;
        cursor: not-allowed !important;
    }

    .activeClass {
        color: #3abd98 ;
        // -webkit-background-clip: text !important;
        // -webkit-text-fill-color: transparent !important;
    }

    .allInactive {
        color: black !important;
    }

    &.collapse {
        // width: 140px;
        min-width: 114px;

        .sideBarText {
            display: none !important;
        }

        .activeClass {
            color: #3abd98 !important;
            // -webkit-background-clip: text !important;
            // -webkit-text-fill-color: transparent !important;
        }

        &.collapse {
            min-width: 140px;

            // width: 110px;
            .sideBarText {
                display: none !important;
            }

            .BdittoLogo {
                width: 4rem;
            }

            .logo-txt {
                margin-left: 4px;
                text-shadow: 0px 1px, 1px 0px, 1px 1px;
                font-size: 20px;
            }

            .sidebarHeader {
                justify-content: center !important;

                .logo {
                    margin-right: 6px !important;
                }
            }

        }
    }

    @media (max-width:780px) {
        .collapse {
            .activeClass {

                svg {
                    background: pink !important;
                    border-radius: 50% !important;

                }

                img {
                    background: pink !important;
                    border-radius: 50% !important;
                }
            }
        }

    }
}

@media (max-width: 1000px) {
    .sideBar {
        min-width: 20%;
        padding-left: 1.5rem !important;

        .BdittoLogo {
            width: 4.5rem !important;
        }

        &.collapse {
            &.collapse {
                min-width: 10%;
                padding-left: 1rem !important;

                .BdittoLogo {
                    width: 2rem !important;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .sideBar {
        min-width: 25%;

        &.collapse {
            &.collapse {
                min-width: 12.5%;
            }
        }
    }
}

@media (max-width: 550px) {
    .sideBar {
        min-width: 30%;

        &.collapse {
            &.collapse {
                min-width: 15%;
            }
        }
    }
}

@media (max-width: 450px) {
    .sideBar {
        min-width: 35%;

        &.collapse {
            &.collapse {
                min-width: 17.5%;
            }
        }
    }
}

@media (max-width: 400px) {
    .sideBar {
        min-width: 40%;

        &.collapse {
            &.collapse {
                min-width: 20%;
            }
        }
    }
}